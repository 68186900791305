import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Slide } from 'react-awesome-reveal';
// import { InView } from 'react-intersection-observer';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Color } from '../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
import Newsletter from '../components/content/newsletter/newsletter';
// import Stockist from '../components/content/stockist/stockist';
import StreamTeam from '../components/content/stream-team/stream-team';
import TrialMonthCycle from '../components/content/trial-month/trial-month-cycle';
import Background from '../components/templates/background/background';
import Breakpoint from '../components/templates/breakpoint/breakpoint';
import Button, { ButtonThemeColor, ButtonThemeType } from '../components/templates/button/button';
import HeadlineCopytext from '../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../components/templates/responsive-image/responsive-image';
import SectionIntended from '../components/templates/section-intended/section-intended';
import YouTubeVideoOverlayContext from '../context/youtube-video-overlay.context';
import { richText } from '../modules/rich-text';

import CycleStyles from './_scss/cycle.module.scss';
import SharedStyles from './_scss/shared.module.scss';
import { InView } from 'react-intersection-observer';
import Stockist from '../components/content/stockist/stockist';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const CyclePage = () => {
    const intl = useIntl();
    const { open: openYouTubeVideoOverlay } = React.useContext(YouTubeVideoOverlayContext);

    const videos = {
        section01: { small: '-PUCSGyAVOM', large: 'yVp4waLU6Ng' },
        section07: { small: 'lX6QwgpCgWs', large: 'EinRXXx4SQw' },
        section10Live: { large: 'gi1jPdcmGM8' },
        section10OnDemand: { large: 'yhG2tqOmtOI' },
    };

    const showVideo = (event: any, videoName: string) => {
        event.preventDefault();
        if (typeof window !== `undefined` && videos[videoName]) {
            const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            const youTubeId =
                deviceWidth < 768 && videos[videoName].small ? videos[videoName].small : videos[videoName].large;
            openYouTubeVideoOverlay(youTubeId, deviceWidth < 768 && videos[videoName].small ? '9:16' : '16:9');
        }
    };

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.cycle.title' })}
            description={intl.formatMessage({ id: 'pages.cycle.description' })}
            topPadding={false}
            pageBackground={PageLayoutBackground.Black}
        >
            {/*01 START*/}
            <div className={SharedStyles.mainVideo}>
                <Breakpoint small={true}>
                    <Background
                        height="177%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/01/background-portrait.jpg"
                        videoPath="_assets/pages/cycle/01/portrait.mp4"
                    />
                </Breakpoint>

                <Breakpoint medium={true} large={true} extraLarge={true} ultraLarge={true}>
                    <Background
                        height="56.25%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/01/background-landscape.jpg"
                        videoPath="_assets/pages/cycle/01/landscape.mp4"
                    />
                </Breakpoint>

                <div className={SharedStyles.mainVideoContent}>
                    <HeadlineCopytext
                        color={Color.White}
                        alignment="center"
                        headline={[
                            {
                                style: 'h1',
                                text: intl.formatMessage({ id: 'pages.cycle.sections.01.headlines.0' }),
                            },
                            {
                                style: 'h2',
                                text: intl.formatMessage({ id: 'pages.cycle.sections.01.headlines.1' }),
                            },
                            {
                                style: 'h1',
                                text: intl.formatMessage({ id: 'pages.cycle.sections.01.headlines.2' }),
                            },
                        ]}
                        copytext={[
                            {
                                text: intl.formatMessage({ id: 'pages.cycle.sections.01.copytext' }),
                            },
                        ]}
                    >
                        <Button
                            type="button"
                            onClick={(e: any) => showVideo(e, 'section01')}
                            theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Transparent }}
                        >
                            <FormattedMessage id="pages.cycle.sections.01.button" />
                        </Button>
                    </HeadlineCopytext>
                </div>
            </div>
            {/*01 END*/}
            <SectionIntended noPadding={true}>
                {/*02 START*/}
                <div className={[SharedStyles.mainHomechamps, SharedStyles.mainHomechampsFabian].join(' ')}>
                    <div className={SharedStyles.mainHomechampsContent}>
                        <HeadlineCopytext
                            color={Color.Green}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: intl.formatMessage({ id: 'pages.cycle.sections.02.headlines.0' }),
                                },
                                {
                                    style: 'h4',
                                    text: intl.formatMessage({ id: 'pages.cycle.sections.02.headlines.1' }),
                                },
                                {
                                    style: 'h3',
                                    text: intl.formatMessage({ id: 'pages.cycle.sections.02.headlines.2' }),
                                },
                                {
                                    style: 'h4',
                                    text: intl.formatMessage({ id: 'pages.cycle.sections.02.headlines.3' }),
                                },
                            ]}
                        />
                        <p className={[SharedStyles.mainHomechampsContentCopytext, 'color-green'].join(' ')}>
                            {richText('pages.cycle.sections.02.copytext')}
                        </p>
                    </div>
                    <div className={SharedStyles.mainHomechampsImage}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/cycle/02/background.png',
                                    media: '',
                                },
                            ]}
                            altText=""
                        />
                    </div>
                </div>
                {/*02 END*/}
                {/*03 START*/}
                <TrialMonthCycle />
                {/*03 END*/}
                {/*04 START*/}
                <div className={SharedStyles.mainHardware}>
                    <ResponsiveImage
                        images={[
                            {
                                path: '_assets/pages/cycle/04/background-small.jpg',
                                media: '(max-width: 767px)',
                            },
                            {
                                path: '_assets/pages/cycle/04/background-medium.jpg',
                                media: '(min-width: 768px) and (max-width: 1024px)',
                            },

                            {
                                path: '_assets/pages/cycle/04/background-large.jpg',
                                media: '(min-width: 1025px)',
                            },
                        ]}
                        altText=""
                    />

                    {/*HEAD START*/}
                    <div className={SharedStyles.mainHardwareHead}>
                        <HeadlineCopytext
                            color={Color.Aqua}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'DIE',
                                },
                                {
                                    style: 'h4',
                                    text: 'HARDWARE',
                                },
                            ]}
                        />

                        <Button
                            href="/downloads/Produktdatenblatt@Cycle.pdf"
                            target="ProduktdatenblattCycle"
                            theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Pink }}
                            className={SharedStyles.mainHardwareButton}
                        >
                            PRODUKTDATENBLATT DOWNLOADEN
                        </Button>
                    </div>

                    {/*HEAD END*/}

                    {/*LIST START*/}
                    <div className={SharedStyles.mainHardwareList}>
                        <div>
                            <h6>1. Rear-Wheel-Design</h6>
                            <p>
                                Das Schwungrad ist hinten verbaut. Dadurch ist es vor deinem Schweiß geschützt und
                                bleibt auch beim härtesten Cycling trocken.
                            </p>
                        </div>

                        <div>
                            <h6>2. 22“ Full-HD-Display</h6>
                            <p>
                                Mit dem brillanten 22“ Full-HD-Display kannst du optimal auf dem @Cycle trainieren –
                                oder davor: Ein schneller Handgriff klappt das Display um.
                            </p>
                        </div>

                        <div>
                            <h6>3. Magnetische Induktionsbremse</h6>
                            <p>
                                Auf dem neuesten Stand: Das Widerstandssystem arbeitet zuverlässig, geräuschlos und ist
                                dabei verschleiß- und wartungsfrei.
                            </p>
                        </div>

                        <div>
                            <h6>4. Riemenantrieb</h6>
                            <p>
                                Anstelle einer herkömmlichen Kette hat das @Cycle einen Riemen. Dadurch läuft es
                                gleichmäßiger und nahezu geräuschfrei.
                            </p>
                        </div>
                    </div>
                    {/*LIST END*/}
                </div>
                {/*04 END*/}
                {/*05 START*/}
                <div className={CycleStyles.section05}>
                    <div className={CycleStyles.section05Highlight}>
                        <ResponsiveImage
                            images={[{ path: '_assets/pages/cycle/05/highlights-1.jpg', media: '' }]}
                            altText=""
                        />
                    </div>

                    <div className={CycleStyles.section05Highlight}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/cycle/05/highlights-2-small.jpg',
                                    media: '(max-width: 767px)',
                                },
                                {
                                    path: '_assets/pages/cycle/05/highlights-2-large.jpg',
                                    media: '(min-width: 768px)',
                                },
                            ]}
                            altText=""
                        />
                        <h6 className="color-white bold">Ergonomischer Multipositionslenker</h6>
                    </div>

                    <div className={CycleStyles.section05Grid}>
                        <div className={CycleStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/05/highlights-3.jpg', media: '' }]}
                                altText=""
                            />
                            <h6 className="color-white bold">Ergonomischer Rennsattel</h6>
                        </div>

                        <div className={CycleStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/05/highlights-4.jpg', media: '' }]}
                                altText=""
                            />

                            <h6 className="color-white bold">Intuitive Sattel- und Lenkereinstellung</h6>
                        </div>

                        <div className={CycleStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/05/highlights-5.jpg', media: '' }]}
                                altText=""
                            />

                            <h6 className="color-white bold">Pedale mit Körbchen und SPD-Klick</h6>
                        </div>

                        <div className={CycleStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/05/highlights-6.jpg', media: '' }]}
                                altText=""
                            />

                            <h6 className="color-white bold">Hebel für reproduzierbare Widerstandseinstellung</h6>
                        </div>

                        <div className={CycleStyles.section05Headline}>
                            <h3 className="color-pink">WEITERE HIGHLIGHTS</h3>
                        </div>
                    </div>
                </div>
                {/*05 END*/}
                {/*06 START*/}
                <div className={[SharedStyles.mainAward, 'gradient-background-animated'].join(' ')}>
                    <div>
                        <Slide direction="right" triggerOnce={false} delay={0}>
                            <HeadlineCopytext
                                color={Color.White}
                                alignment="left"
                                headline={[
                                    {
                                        style: 'h3',
                                        text: 'AUSGEZEICHNETES',
                                    },
                                    {
                                        style: 'h4',
                                        text: 'DESIGN',
                                    },
                                ]}
                                copytext={[
                                    {
                                        text:
                                            'Klobige Fitnessmaschinen gehören ins Studio, nicht ins Wohnzimmer. Deshalb fügt sich das stylische @Cycle mit kompakten Abmessungen von nur 140 x 56,5 x 127 cm organisch in deinen Lebensraum ein. Und wurde dafür auch mit dem Good Design Award prämiert.',
                                    },
                                ]}
                            />
                        </Slide>
                    </div>

                    <div>
                        <div className={SharedStyles.mainAwardImage}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/06/good-design-award-logo.png', media: '' }]}
                                altText=""
                            />
                        </div>
                    </div>
                </div>
                {/*06 END*/}
            </SectionIntended>
            {/*07 START*/}
            <div className={SharedStyles.mainVideo}>
                <Breakpoint small={true}>
                    <Background
                        height="177%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/07/background-portrait.jpg"
                        videoPath="_assets/pages/cycle/07/portrait.mp4"
                    />
                </Breakpoint>

                <Breakpoint medium={true} large={true} extraLarge={true} ultraLarge={true}>
                    <Background
                        height="56.25%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/07/background-landscape.jpg"
                        videoPath="_assets/pages/cycle/07/landscape.mp4"
                    />
                </Breakpoint>

                <div className={SharedStyles.mainVideoContent}>
                    <Slide direction="up" triggerOnce={true} delay={0}>
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="center"
                            headline={[
                                {
                                    style: 'h1',
                                    text: 'THE NEW',
                                },
                                {
                                    style: 'h2',
                                    text: 'MUSCLE-HAVE',
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'Heimsportler trainieren, was sie wollen – Home Champs trainieren, was sie lieben. Denn mit Horizon@ findest du dein Lieblings-Workout in unzähligen CYBEROBICS LIVE und On-Demand-Classes für jedes Level, auf dem @Cycle oder daneben, solo oder in der Gruppe.',
                                },
                            ]}
                        >
                            <Button
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section07')}
                                theme={{
                                    type: ButtonThemeType.SolidWhite,
                                    color: ButtonThemeColor.Transparent,
                                }}
                            >
                                Video ansehen
                            </Button>
                        </HeadlineCopytext>
                    </Slide>
                </div>
            </div>
            {/*07 END*/}
            <SectionIntended noPadding={true}>
                {/*08 START*/}
                <div className={SharedStyles.mainTraining}>
                    <div className={SharedStyles.mainTrainingHead}>
                        <HeadlineCopytext
                            alignment="center"
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'DAS TRAINING',
                                    color: Color.Aqua,
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'In den LIVE Classes von CYBEROBICS, mit multidimensionalen Licht- und Soundshows, sorgen die Profi-Trainer dafür, dass du dich nach jedem Workout wie ein echter Champ fühlst.',
                                    color: Color.White,
                                    style: 'bold',
                                },
                            ]}
                        />
                    </div>

                    <div className={SharedStyles.mainTrainingList}>
                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconCycle].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/cycle/08/cycling.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Cycling</p>
                            <p>
                                Trainiere in wöchentlich über 50 verschiedenen Cycling LIVE Classes für alle Ansprüche:
                                Kalorien verbrennen und Körper straffen, Herz-Kreislaufsystem auf Touren bringen,
                                komplett auspowern oder einfach mal abschalten.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconStrength].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/cycle/08/strength.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Strength</p>
                            <p>
                                In den LIVE Classes STRONG, PUMP und SIXPACK pushen unsere Trainer dich zu spür- und
                                sichtbar mehr Power im gesamten Körper.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconCardio].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/cycle/08/cardio.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Cardio</p>
                            <p>
                                Die schweißtreibenden Cardio LIVE Classes BURN, BOX, SWEAT & DANCE bringen dich an deine
                                Grenzen – und wenn du willst, auch darüber hinaus.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconToning].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/cycle/08/toning.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Toning</p>
                            <p>
                                Bei TONE, STEP, HEALTH und BARRE straffst und formst du deinen Körper – oder ausgewählte
                                Körperpartien – mit hocheffektiven Bodyweight-Übungen.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconBodyMind].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/cycle/08/body-mind.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Body & Mind</p>
                            <p>
                                FLOW, PILATES und RELAX maximieren deine innere Stärke – auch ganz wörtlich dank
                                kräftiger Tiefenmuskulatur und besserer Koordination. Funktionales Rückentraining gibt’s
                                bei der LIVE Class BACK.
                            </p>
                        </div>
                    </div>
                </div>
                {/*08 END*/}
                {/*09 START*/}
                <StreamTeam />
                {/*09 END*/}
                {/*10 START*/}
                <div className={SharedStyles.mainClasses}>
                    <div className={SharedStyles.mainClassesImageContainer}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/cycle/10/live-landscape.jpg',
                                    media: '(max-width: 767px), (min-width: 1025px)',
                                },
                                {
                                    path: '_assets/pages/cycle/10/live-portrait.jpg',
                                    media: '(min-width: 768px) and (max-width: 1024px)',
                                },
                            ]}
                            altText=""
                        />
                        <div className={SharedStyles.mainClassesImageContainerContent}>
                            <Slide direction="left" triggerOnce={false} delay={0}>
                                <HeadlineCopytext
                                    color={Color.White}
                                    alignment="center"
                                    headline={[
                                        {
                                            style: 'h5',
                                            text: 'LIVE',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'CLASSES',
                                        },
                                    ]}
                                />
                            </Slide>
                            <Button
                                className={SharedStyles.mainClassesImageContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10Live')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Purple }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesTextContainer}>
                        <div className={SharedStyles.mainClassesTextContainerContent}>
                            <p className="bold">Live</p>
                            <p>
                                In wöchentlich über 150 neuen CYBEROBICS LIVE Classes aus den Kategorien Cardio,
                                Strength, Toning, Body & Mind und Cycling pushen dich spezialisierte Trainer an dein
                                individuelles Ziel. Danach kannst du alle LIVE Classes noch eine Woche lang in der LIVE
                                Collection abrufen.
                            </p>
                            <Button
                                className={SharedStyles.mainClassesTextContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10Live')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Purple }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesImageContainer}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/cycle/10/on-demand-landscape.jpg',
                                    media: '(max-width: 767px), (min-width: 1025px)',
                                },
                                {
                                    path: '_assets/pages/cycle/10/on-demand-portrait.jpg',
                                    media: '(min-width: 768px) and (max-width: 1024px)',
                                },
                            ]}
                            altText=""
                        />
                        <div className={SharedStyles.mainClassesImageContainerContent}>
                            <Slide direction="right" triggerOnce={false} delay={0}>
                                <HeadlineCopytext
                                    color={Color.White}
                                    alignment="center"
                                    headline={[
                                        {
                                            style: 'h5',
                                            text: 'On-',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'Demand',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'Workouts',
                                        },
                                    ]}
                                />
                            </Slide>
                            <Button
                                className={SharedStyles.mainClassesImageContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10OnDemand')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Purple }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesTextContainer}>
                        <div className={SharedStyles.mainClassesTextContainerContent}>
                            <p className="bold">On demand</p>
                            <p>
                                Rund um die Uhr stehen dir unzählige professionelle On-Demand-Workouts für jedes Ziel
                                und jedes Level zum Streaming bereit.
                            </p>
                            <Button
                                className={SharedStyles.mainClassesTextContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10OnDemand')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Purple }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>
                </div>
                {/*10 END*/}
                {/*11 START*/}
                <div className={SharedStyles.mainSlider}>
                    <div className={SharedStyles.mainSliderSlider}>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={true}
                            onSlideChange={() => {
                                // do something
                            }}
                            onSwiper={() => {
                                // do something
                            }}
                            pagination={{ clickable: true }}
                            navigation={true}
                        >
                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'BUDDY',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'BUILDING',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Leaderboard</h6>
                                        <p className="color-white">
                                            Auf dem Leaderboard kannst du mit anderen Home Champs im direkten Wettstreit
                                            trainieren.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/cycle/11/slider-1.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'BUDDY',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'BUILDING',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Vernetzen</h6>
                                        <p className="color-white">
                                            Finde ganz einfach neue Trainings-Buddys in der @Cycle Community und
                                            vernetze dich mit ihnen.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/cycle/11/slider-2.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'BUDDY',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'BUILDING',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Erfolgs-Tracking</h6>
                                        <p className="color-white">
                                            Tracke, analysiere und perfektioniere deinen Fortschritt für noch mehr
                                            Wettbewerb.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/cycle/11/slider-3.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                {/*11 END*/}
            </SectionIntended>

            {/*15 NEWSLETTER START*/}
            <div className={CycleStyles.sectionNewsletter}>
                <SectionIntended>
                    <Newsletter
                        headline="NEWSLETTER"
                        buttonTheme={{
                            type: ButtonThemeType.Gradient,
                            color: ButtonThemeColor.PurpleGreen,
                        }}
                        theme="page"
                    />
                </SectionIntended>
            </div>
            {/*15 NEWSLETTER END*/}

            {/*12 START*/}
            <div className={CycleStyles.section12}>
                <ResponsiveImage
                    images={[
                        { path: '_assets/pages/cycle/12/background-small.jpg', media: '(max-width: 767px)' },
                        { path: '_assets/pages/cycle/12/background-large.jpg', media: '(min-width: 768px)' },
                    ]}
                    altText=""
                />

                <div className={CycleStyles.section12Content}>
                    <Slide direction="right" triggerOnce={false} delay={0}>
                        <HeadlineCopytext
                            alignment="right"
                            color={Color.White}
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'MACHT EUCH WARM',
                                },
                                {
                                    style: 'h4',
                                    text: 'HOME CHAMPS',
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'Eine ganze Welt des Sports wartet darauf, erkundet zu werden – direkt neben dem Sofa.',
                                },
                            ]}
                        />
                    </Slide>
                </div>
            </div>
            {/*12 END*/}
            <SectionIntended noPadding={true}>
                {/*13 START*/}
                <TrialMonthCycle />
                {/*13 END*/}

                {/*14 STORE START*/}
                <InView triggerOnce={true}>
                    {({ inView, ref }) => (
                        <div className={SharedStyles.sectionStockist} ref={ref}>
                            {inView && <Stockist type="cycle" />}
                        </div>
                    )}
                </InView>
                {/*15 STORE END*/}
            </SectionIntended>
            {/*16 START*/}
            <div className={SharedStyles.mainVideo}>
                <Breakpoint small={true}>
                    <Background
                        height="177%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/16/background-portrait.jpg"
                        videoPath="_assets/pages/cycle/16/portrait.mp4"
                    />
                </Breakpoint>

                <Breakpoint medium={true} large={true} extraLarge={true} ultraLarge={true}>
                    <Background
                        height="56.25%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/cycle/16/background-landscape.jpg"
                        videoPath="_assets/pages/cycle/16/landscape.mp4"
                    />
                </Breakpoint>

                <div className={SharedStyles.mainVideoContent}>
                    <Slide direction="up" triggerOnce={false} delay={0}>
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="center"
                            headline={[
                                {
                                    style: 'h1',
                                    text: 'DER',
                                },
                                {
                                    style: 'h2',
                                    text: 'HORIZON@MIRROR',
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'Deine private Direktverbindung zu Profi-Trainern, Trainings-Buddys – und dir selbst. ',
                                },
                            ]}
                        >
                            <Button
                                type="button"
                                to="/mirror"
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Transparent }}
                            >
                                JETZT DEN @MIRROR KENNENLEREN
                            </Button>
                        </HeadlineCopytext>
                    </Slide>
                </div>
            </div>
            {/*16 END*/}

            {/*FOOTNOTES START*/}
            <SectionIntended>
                <div className={SharedStyles.footnotes}>
                    <div className={SharedStyles.footnotesInner}>
                        <table>
                            <tr>
                                <td>
                                    <p>
                                        <small>(1)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(1)">
                                        <p>
                                            <small>
                                                Beim Kauf eines @Cycles ist ein Horizon@Abo notwendig, welches
                                                zusätzlich erworben werden muss.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        <small>(2)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(2)">
                                        <p>
                                            <small>
                                                Der Kauf eines @Cycles ist mit einer unverbindlichen 30-tägigen
                                                Testphase verbunden. Du hast Anspruch auf eine 30-tägige Testphase pro
                                                Haushalt/Lieferadresse. Das Angebot ist nicht übertragbar und gilt nur
                                                für Kunden in Deutschland und Österreich innerhalb des Liefergebiets der
                                                Johnson Health Tech. GmbH. Das Angebot über eine 30-tägige Testphase
                                                gilt nur für Verbraucher, die online auf www.shop-horizonfitness.eu ein
                                                @Cycle oder einen @Mirror erworben haben und ist nicht für Unternehmen
                                                verfügbar. Das 30-tägige Probetraining beginnt mit der Aktivierung des
                                                @Cycles mit dem bereitgestellten Aktivierungscode.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        <small>(3)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(3)">
                                        <p>
                                            <small>
                                                Sofern die Lieferart „Lieferung zum Aufstellort“ gewählt wurde, werden
                                                die Versandkosten nicht zurückerstattet. Des Weiteren fallen im Zuge
                                                einer Retoure, durch Abholung unseres Spediteurs, Kosten i.H.v. 95 Euro
                                                an. Eine explizite Kündigung des Horizon@Abos ist im Falle einer
                                                Rückgabe des Gerätes innerhalb der 30-tägigen Testphase nicht
                                                erforderlich. Diese endet automatisch, sofern das @Cycle online auf
                                                www.shop-horizonfitness.eu erworben wurde.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            {/*<tr>*/}
                            {/*    <td>*/}
                            {/*        <p>*/}
                            {/*            <small>(4)</small>*/}
                            {/*        </p>*/}
                            {/*    </td>*/}
                            {/*    <td>*/}
                            {/*        <div id="footnote-(4)">*/}
                            {/*            <p>*/}
                            {/*                <small>*/}
                            {/*                    Aktion gilt bis zum 15.11.2021. Bei Rückgabe des @Cycles innerhalb der*/}
                            {/*                    Testphase ist der Horizon Fitness Bluetooth Brustgurt ebenfalls zu*/}
                            {/*                    retournieren. Andernfalls werden die Kosten für das @Cycle abzüglich der*/}
                            {/*                    Kosten für den Horizon Fitness Bluetooth Brustgurt in Höhe von 59,90€*/}
                            {/*                    erstattet.*/}
                            {/*                </small>*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                        </table>
                    </div>
                </div>
            </SectionIntended>
            {/*FOOTNOTES END*/}
        </PageLayout>
    );
};

export default CyclePage;
